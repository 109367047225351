<template>
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template>
            <b-overlay :show="loader">
              <div>
                <div class="application-form-wrapper application-form-view-wrapper" style="padding: 0 2rem;">
                  <div>
                    <!-- Application view start -->
                    <div class="application-form-wrapper application-form-view-wrapper mt-5">
                        <div class="application-form-wrapper">
                        <div class="application-itmes">
                            <b-row>
                                <b-col sm="12">
                                    <div class="group-form-card">
                                        <b-card>
                                                <b-row>
                                                    <b-col class="ml-5 pl-5 mr-3 mt-5">
                                                        <div class="mb-5 section-head">
                                                            <span>{{ checkBn ? 'বরাবর' : 'To' }}</span><br>
                                                            <span>{{ checkBn ? 'সিনিয়র সচিব/সচিব' : 'Senior Secretary/Secretary' }}</span><br>
                                                            <span>{{ checkBn ? 'বাণিজ্য মন্ত্রণালয়' : 'Ministry of Commerce' }}</span><br>
                                                            <span>{{ checkBn ? 'বাংলাদেশ সচিবালয়, ঢাকা।' : 'Bangladesh Secretariat, Dhaka.' }}</span>
                                                        </div>
                                                        <div class="section-subject">
                                                            <b-row>
                                                                <div class="col-sm-1 font-weight-bold" style="max-width: 10.333333% !important;">{{ checkBn ? 'বিষয়' : 'Subject' }}: </div>
                                                                <div class="col-sm-5">
                                                                    <p style="border-bottom: 1px dotted">{{ data.subject }}</p>
                                                                </div>
                                                                <div style="max-width: 49% !important;" class="col-sm-6">{{ checkBn ? 'শীর্ষক স্থানীয়/আন্তর্জাতিক বাণিজ্য মেলা আয়োজনের অনুমতি প্রদান।' : 'grant of permission for local/international trade fairs.'}} </div>
                                                            </b-row>
                                                        </div>
                                                        <div class="mb-2 mt-4">{{ checkBn ? 'মহোদয়' : 'Dear Sir' }},</div>
                                                        <div class="section-body mt-2">
                                                            <b-row>
                                                                <div :class="checkBn ? 'col-sm-3' : 'col-sm-3'">{{ checkBn ? 'সবিনয় নিবেদন এই যে, আমি' : 'I am interested at location' }} </div>
                                                                <div :class="checkBn ? 'col-sm-4' : 'col-sm-4'">
                                                                    <p style="border-bottom: 1px dotted">{{ data.applicant_name }}</p>
                                                                </div>
                                                                <div :class="checkBn ? 'col-sm-5' : 'col-sm-5'">
                                                                  <p style="border-bottom: 1px dotted">{{ data.place }}</p>
                                                                </div>
                                                            </b-row>
                                                            <b-row>
                                                                <div :class="checkBn ? 'col-sm-1' : 'col-sm-5'">{{ checkBn ? 'স্থানে' : 'for organizing Local/International Trade Fair  from' }} </div>
                                                                <div :class="checkBn ? 'col-sm-4' : 'col-sm-3'">
                                                                  <p style="border-bottom: 1px dotted">{{ data.from_date | dateFormat }}</p>
                                                                </div>
                                                                <div :class="checkBn ? 'col-sm-2' : 'col-sm-1'">{{ checkBn ? 'তারিখ থেকে' : 'to' }} </div>
                                                                <div :class="checkBn ? 'col-sm-4' : 'col-sm-3'">
                                                                  <p style="border-bottom: 1px dotted">{{ data.to_date | dateFormat }}</p>
                                                                </div>
                                                                <div v-if="checkBn" class="col-sm-1"> {{ 'পর্যন্ত' }}</div>
                                                            </b-row>
                                                            <b-row>
                                                                <div v-if="!checkBn" class="col-sm-1"> {{ 'name' }}</div>
                                                                <div class="col-sm-11">
                                                                  <p style="border-bottom: 1px dotted">{{ data.fair_name }}</p>
                                                                </div>
                                                                <div v-if="checkBn" class="col-sm-1"> {{ 'শীর্ষক' }}</div>
                                                                <div v-if="data.fair_type == 1" class="col-sm-1"> {{ 'স্থানীয়' }}</div>
                                                                <div v-else class="col-sm-1"> {{ 'আন্তর্জাতিক' }}</div>
                                                                <div class="col-sm-10 text-justify">{{ checkBn ? 'বাণিজ্য মেলা আয়োজন করতে আগ্রহী। এ বিষয়ে প্রয়োজনীয় কাগজপত্র ও তথ্যাদি আপনার সদয় বিবেচনা ও প্রয়োজনীয় পদক্ষেপ' : 'I have presented the necessary documents and information in this regard for your kind consideration and necessary action.'}}</div>
                                                                <div class="col-sm-10 text-justify">{{ checkBn ? ' গ্রহণের জন্য উপস্থাপন করলাম।' : 'I have presented the necessary documents and information in this regard for your kind consideration and necessary action.'}}</div>
                                                            </b-row>
                                                            <br>
                                                            <br>
                                                            <b-row>
                                                              <div class="col-sm-1">
                                                                {{$t('globalTrans.date')}}
                                                              </div>
                                                                <div class="col-sm-5">
                                                                  <p style="border-bottom: 1px dotted">{{ data.application_date | dateFormat }}</p>
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <b-row>
                                                                        <div class="col-sm-6"></div>
                                                                        <div class="col-sm-6 text-center">
                                                                            <span>{{ checkBn ? 'বিনীত নিবেদক' : 'Regards-' }}</span><br>
                                                                            <img
                                                                            style="width: 106px;height: 30px;"
                                                                            class = "img-fluid"
                                                                            :src="internationalTradeFairServiceBaseUrl + 'storage/' + data.signature_attachment" />
                                                                            <br>
                                                                            <span>{{ data.applicant_name }}</span>
                                                                            <br>
                                                                            <span>{{ data.designation }}</span>
                                                                        </div>
                                                                    </b-row>
                                                                </div>
                                                            </b-row>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                        </b-card>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                      </div>
                      <!-- documents -->
                      <b-row>
                          <b-col md="6">
                          <div>
                            <h6 class="mb-3 tag">{{ $t('dealer.important_document') }}</h6>
                          </div>
                          <div>
                            <ul>
                              <!-- <li v-if="data?.payment_challan_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(1) }}. {{ $t('ditfDivisionAppManagement.payment_challan_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="data.payment_challan_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + data.payment_challan_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li> -->
                              <li v-if="data?.place_allotment_letter_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(1) }}. {{ $t('ditfDivisionAppManagement.place_allotment_letter_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="data.place_allotment_letter_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + data.place_allotment_letter_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="data?.trade_license_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(2) }}. {{ $t('ditfDivisionAppManagement.trade_license_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="data.trade_license_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + data.trade_license_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="data?.income_tax_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(3) }}. {{ $t('ditfDivisionAppManagement.income_tax_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="data.income_tax_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + data.income_tax_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="data?.vat_certificate_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(4) }}. {{ $t('ditfDivisionAppManagement.vat_certificate_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="data.vat_certificate_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + data.vat_certificate_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="data?.payment_challan_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(5) + '. ' + (checkBn ? 'পণ্য বা সেবা সংশ্লিষ্ট নিবন্ধিত এসোসিয়েশন/চেম্বার এর সুপারিশসহ মতামত' : 'Opinions including recommendations of registered associations/chambers regarding products or services') }}</span>
                                  <div class="list-btn">
                                    <div v-if="data.payment_challan_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + data.payment_challan_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="data?.to_license_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(2) }}. {{ $t('ditfDivisionAppManagement.to_license_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="data.to_license_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + data.to_license_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="data?.certificate_of_incorporation_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(3) }}. {{ $t('ditfDivisionAppManagement.certificate_of_incorporation_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="data.certificate_of_incorporation_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + data.certificate_of_incorporation_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                              <li v-if="data?.noc_attachment">
                                <div class="d-flex justify-content-between">
                                  <span>{{ $n(4) }}. {{ $t('ditfDivisionAppManagement.noc_attachment') }}</span>
                                  <div class="list-btn">
                                    <div v-if="data.noc_attachment">
                                      <i class="ri-check-line yes-btn" style="font-weight: 700"></i>
                                      <a target="_blank" :href="internationalTradeFairServiceBaseUrl + 'storage/' + data.noc_attachment"><i
                                          style="font-weight:bold" class="ri-download-line download-btn"></i></a>
                                    </div>
                                    <i v-else class="ri-close-line no-btn" style="font-weight: 700"></i>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                </div>
              </div>
            </b-overlay>
          </template>
        </body-card>
      </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { applicationPaymentDetails } from '../../../api/routes'
// import ListReportHead from '@/components/custom/ListReportHead.vue'
export default {
    name: 'Details',
    props: ['id'],
    components: {
      // ListReportHead
    },
    data () {
      return {
        internationalTradeFairServiceBaseUrl: internationalTradeFairServiceBaseUrl,
        loader: false,
        detailsData: [],
        stallCategoryList: [],
        circular: {},
        data: {},
        stallList: [],
        stalls: [],
        circular_name: '',
        fair_name: ''
      }
    },
    computed: {
    applicantTypeList () {
      return [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'প্রতিষ্ঠান/কোম্পানি/ব্যক্তি' : 'Institute/Company/Person', text_bn: 'প্রতিষ্ঠান/কোম্পানি/ব্যক্তি', text_en: 'Institute/Company/Person' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'এসোসিয়েশন/চেম্বার/সমিতি' : 'Association/Chamber/Committee', text_bn: 'এসোসিয়েশন/চেম্বার/সমিতি', text_en: 'Association/Chamber/Committee' }
      ]
    },
      fairTypeList () {
          return [
            { value: 1, text: this.$i18n.locale === 'bn' ? 'স্থানীয়' : 'Local', text_bn: 'স্থানীয়', text_en: 'Local' },
            { value: 2, text: this.$i18n.locale === 'bn' ? 'আন্তর্জাতিক' : 'International', text_bn: 'আন্তর্জাতিক', text_en: 'International' }
          ]
        },
      checkBn () {
        return this.$i18n.locale === 'bn' ? 1 : 0
      },
      listData: function () {
        const tmpData = this.$store.state.list
        return JSON.parse(JSON.stringify(tmpData))
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'fair_name' },
            { key: 'applicant_name' },
            { key: 'status' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'fair_name_en' },
            { key: 'applicant_name' },
            { key: 'status' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      currentLocale () {
        return this.$i18n.locale
      },
      fiscalYearList () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(el => el.status === 1).map(el => {
          return { value: el.value, text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en }
        })
      }
    },
    created () {
      if (this.id) {
        this.getData(this.id)
      }
    },
    methods: {
      async getData (id) {
        this.loader = true
          let result = null
          result = await RestApi.getData(internationalTradeFairServiceBaseUrl, `${applicationPaymentDetails}/${id}`)
          this.data = result.data
          this.loader = false
      },
      BangNum (n) {
         if (this.$i18n.locale === 'bn') {
            return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
         }
         return n
      },
      getCompanyTypeName (id) {
        const companyType = this.$store.state.TradeFairService.commonObj.companyTypeList.find(obj => obj.value === parseInt(id))
        if (typeof companyType !== 'undefined') {
          return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
        } else {
          return ''
        }
      },
      getProductionUnit (id) {
        const companyType = this.$store.state.TradeFairService.commonObj.measurementUnitList.find(obj => obj.value === parseInt(id))
        if (typeof companyType !== 'undefined') {
          return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
        } else {
          return ''
        }
      },
      getCompanyNatureName (id) {
        const companyNature = this.$store.state.TradeFairService.commonObj.companyNatureList.find(obj => obj.value === parseInt(id))
        if (typeof companyNature !== 'undefined') {
          return this.$i18n.locale === 'en' ? companyNature.text_en : companyNature.text_bn
        } else {
          return ''
        }
      },
      getMeasurementUnitName (Id) {
        const Obj = this.$store.state.ExternalUserService.tradeFair.commonObj.measurementUnitList.find(item => item.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
          return this.$i18n.locale === 'en' ? Obj.text_en : Obj.text_bn
        }
        return ''
      },
      countryName (Id) {
        const Obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
            return this.$i18n.locale === 'bn' ? Obj.text_bn : Obj.text_en
        } else {
          return ''
        }
      },
    getApplicantType (id) {
      const data = this.applicantTypeList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return data !== 'undefined' ? data.text_bn : ''
      } else {
        return data !== 'undefined' ? data.text_en : ''
      }
    },
    getFairType (id) {
      const data = this.fairTypeList.find(item => item.value === id)
      if (this.currentLocale === 'bn') {
        return data !== 'undefined' ? data.text_bn : ''
      } else {
        return data !== 'undefined' ? data.text_en : ''
      }
    },
      nationalityName (Id) {
        const Obj = this.$store.state.CommonService.commonObj.countryList.find(item => item.value === parseInt(Id))
        if (typeof Obj !== 'undefined') {
          return this.$i18n.locale === 'bn' ? Obj.nationality_bn : Obj.nationality
        } else {
          return ''
        }
      }
    }
}
</script>
<style>
.download-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  padding: 1px 3px;
}

.yes-btn {
  color: green;
  border: 1px solid gray;
  margin-left: 5px;
  font-size: 18px;
  font-weight: 700;
  padding: 1px 3px;
}
</style>
